import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import CommonService from "../../Services/Common";
import { Nav, Navbar, NavDropdown, Row, Col, Dropdown } from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUser, faHeart, faCar as car } from "@fortawesome/free-solid-svg-icons";
import AuthSidebar from "../Auth";
import Menu from "./Menu";
import { Auth } from "../../Context/Auth.context";
import { Data } from "../../Context/Data.context";
import { urlMaker } from "../../helpers";
import "./styles.scss";

const Header = () => {
  const { category, setCategory } = Data();
  const { isAuthenticated, activateToken, activateAuthentication } = Auth();
  const history = useHistory();
  const location = useLocation();
  const categories = Data().categories;

  const [input, setInput] = useState();
  // const [category, setCategory] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [menuDropdown, setMenuDropdown] = useState(false);

  const [authVisible, setAuthVisible] = useState(false);
  const hideAuth = () => setAuthVisible(false);
  const showAuth = (path) => {
    if (isAuthenticated) {
      history.push(path);
    } else {
      setAuthVisible(true);
    }
  };

  useEffect(() => {
    if (categories && categories[0]) {
      setCategory(categories[0]);
    }
  }, [categories]);

  const logout = () => {
    activateAuthentication(false);
    activateToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("refreshCycle");
    localStorage.removeItem("fav");
  };

  const getSuggestion = (text) => {
    setInput(text);
    CommonService.getSuggestion(category, text).then((res) => {
      if (res.data && res.data.length) setSuggestions(res.data);
    });
  };
  const search = () => {
    if (category.category == "Parts") {
      history.push(`/shop/search/-/${input ? `str_${urlMaker(input)}` : ``}`);
    } else {
      history.push(`/search/${category.slug}/-/${input ? `str_${urlMaker(input)}` : ``}`);
    }
  };

  const changeCategory = (category) => {
    setCategory(category);

    if (location.pathname.includes("/search/") || location.pathname.includes("/search")) {
      history.push(`/search/${category.slug}/-/`);
    }
  };

  return (
    <div className="mt-n4" id="autoOnes-header">
      <Navbar className="mt-n0" collapseOnSelect expand="lg" bg="bg-light" variant="dark">
        <Link to="/" className="navbar-brand" href="#home">
          <img alt="AutoOnes Logo" width="160px" src="/Assests/logo.png" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="d-flex justify-content-between" id="responsive-navbar-nav">
          <Menu categories={categories} />

          <Nav>
            <div className="d-flex align-self-center">
              <Dropdown className="mt-4">
                <Dropdown.Toggle onClick={() => showAuth("/choose-category")} className="postAdBtn" id="dropdown-basic">
                  <a className="text-white cursor-pointer" style={{ color: "#fff!important" }}>
                    Post an Ad
                  </a>
                </Dropdown.Toggle>
              </Dropdown>
              <div className="nav-link text-center">
                <a className="cursor-pointer" onClick={() => showAuth("/user/my-favorites")}>
                  <FontAwesomeIcon className="mb-0 ml-0 heart-icon" icon={faHeart} />
                  <div style={{ fontSize: "0.8rem" }}>Saved</div>
                </a>
              </div>
              <div className="nav-link text-center  ">
                {isAuthenticated ? (
                  <Dropdown show={menuDropdown} onMouseEnter={() => setMenuDropdown(true)} onMouseLeave={() => setMenuDropdown(false)}>
                    <a className="cursor-pointer" onClick={() => showAuth("/user/dashboard")}>
                      <FontAwesomeIcon className="mb-0 user-icon" icon={faUser} />
                      <div style={{ fontSize: "0.8rem" }}>Account</div>
                    </a>
                    <Dropdown.Menu className="dropdown-menubar account-dropdown">
                      <div className="d-flex flex-lg-row flex-column">
                        <Link className="dropdown-item border-0" to={"/user/dashboard"}>
                          <div className="mx-3 my-2">Dashboard</div>
                        </Link>
                      </div>
                      <div className="d-flex flex-lg-row flex-column">
                        <Link className="dropdown-item border-0" to={"/user/my-inventory"}>
                          <div className="mx-3 my-2">My Inventory</div>
                        </Link>
                      </div>
                      <div className="d-flex flex-lg-row flex-column">
                        <Link className="dropdown-item border-0" to={"/user/my-favorites"}>
                          <div className="mx-3 my-2">My Favorites</div>
                        </Link>
                      </div>
                      <div className="d-flex flex-lg-row flex-column">
                        <Link className="dropdown-item border-0" to={"/user/profile"}>
                          <div className="mx-3 my-2">My Profile</div>
                        </Link>
                      </div>
                      <div className="d-flex flex-lg-row flex-column">
                        <Link className="dropdown-item border-0" to={"/user/orders"}>
                          <div className="mx-3 my-2">My Orders</div>
                        </Link>
                      </div>
                      <div className="d-flex flex-lg-row flex-column">
                        <Link className="dropdown-item border-0" to={"/user/messages"}>
                          <div className="mx-3 my-2">My Messages</div>
                        </Link>
                      </div>
                      <div className="d-flex flex-lg-row flex-column">
                        <a className="dropdown-item border-0 cursor-pointer" onClick={logout}>
                          <div className="mx-3 my-2">Logout</div>
                        </a>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <a className="cursor-pointer" onClick={() => showAuth("/login")}>
                    <FontAwesomeIcon className="mb-0 user-icon" icon={faUser} />
                    <div style={{ fontSize: "0.8rem" }}>Sign In</div>
                  </a>
                )}
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>

        {/* AuthSidebar Component */}
        <AuthSidebar authVisible={authVisible} hideAuth={hideAuth} />
      </Navbar>

      {location.pathname.includes("/videos") ||
      location.pathname.includes("/video") ||
      location.pathname.includes("/new") ||
      location.pathname.includes("/support") ||
      location.pathname.includes("/faqs") ||
      location.pathname.includes("/price") ? (
        <div></div>
      ) : (
        <div className="container-fluid px-4 mt-n4">
          <Row className="no-gutters header-search-bar">
            <Col md={3} className="details">
              <div className="d-flex">
                <div className="border-right d-flex">
                  <div className="align-self-center px-2">
                    <img className="px-2" style={{ height: "25px" }} src={`${process.env.REACT_APP_AWS_URL}${category.logo}`} />
                  </div>
                </div>
                <div className="content">
                  <p className="mb-0">Select Your Vehicles Interest</p>
                  <NavDropdown title={<span className="text-capitalize">Used {category.category}</span>} id="nav-dropdown">
                    {categories &&
                      categories.map((category, i) => (
                        <NavDropdown.Item key={i} onClick={() => changeCategory(category)}>
                          {category.category}
                        </NavDropdown.Item>
                      ))}
                  </NavDropdown>
                </div>
              </div>
            </Col>
            <Col md={9} className="d-flex">
              <Autocomplete
                items={suggestions}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={{
                      background: isHighlighted ? "var(--red)" : "white",
                      color: isHighlighted ? "white" : "black",
                      padding: "15px",
                    }}
                  >
                    {item.label}
                  </div>
                )}
                value={input}
                inputProps={{
                  placeholder: `Search ${category.category} By Make or Model`,
                  style: {
                    width: "100%",
                    height: "100%",
                    border: "none",
                  },
                }}
                wrapperProps={{ className: "auto-complete" }}
                wrapperStyle={{ width: "100%" }}
                menuStyle={autoCompleteMenuStyle}
                onSelect={(item) => setInput(item)}
                onChange={(e) => getSuggestion(e.target.value)}
                getItemValue={(item) => item.label}
              />
              <div className="d-flex align-self-center">
                <FontAwesomeIcon onClick={search} className="search-icon mr-3 cursor-pointer" icon={faSearch} />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

const autoCompleteMenuStyle = {
  borderRadius: "0px",
  border: "1px solid #dfdfdf",
  background: "rgba(255, 255, 255, 1)",
  padding: "0px 0px",
  fontSize: "90%",
  overflow: "auto",
  position: "absolute",
  top: "46px",
  left: "0px",
  cursor: "pointer",
  maxHeight: "300px",
  opacity: "1",
  zIndex: "100",
};

export default Header;
