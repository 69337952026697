import React from "react";
import Axios from "axios";
import { api } from "../../config/app.config";

const Common = {};

Common.getCategory = async (slug) => {
  return await Axios.get(`${api}category/${slug}`);
};

Common.getMakerByCategory = async (category, query) => {
  const { limit } = query;
  return await Axios.get(
    `${api}maker/category/${category.id}?${limit ? `&limit=${limit}` : ""}`
  );
};

Common.getModelByCategory = async (category) => {
  return await Axios.get(`${api}model/category/${category.id}`);
};

Common.getModelByMaker = async (maker, query) => {
  return await Axios.get(`${api}model/maker/${maker.id}`);
};

Common.getVariantByModel = async (model) => {
  return await Axios.get(`${api}variation/model/${model.id}`);
};

Common.getProductCategory = async () => {
  return await Axios.get(`${api}shopcategory/`);
};

Common.getProductSubCategory = async () => {
  return await Axios.get(`${api}subcategory`);
};

Common.getProductSubCategoryOptionByCategory = async (id) => {
  return await Axios.get(`${api}subcategory/category/option/${id}`);
};

Common.getComparison = async () => {
  return await Axios.get(`${api}comparison`);
};

Common.getComparisonByCategory = async (id) => {
  return await Axios.get(`${api}comparison?category=${id}`);
};

Common.getCityByCountry = async (id) => {
  return await Axios.get(`${api}city/country/7`);
};

Common.getAreaByCity = async (query) => {
  const { city } = query;
  return await Axios.get(`${api}area?${city ? `&city=${city}` : ""}`);
};

Common.getBodyTypeByCategory = async (id) => {
  return await Axios.get(`${api}bodytype/category/${id}`);
};

Common.getColors = async () => {
  return await Axios.get(`${api}color`);
};

Common.getSuggestion = async (category, text) => {
  return await Axios.get(
    `${api}listing/suggestive?${text ? `&text=${text}` : ""}&category=${
      category.id
    }${category.category == "Parts" ? `&parts=true` : ""}`
  );
};

export default Common;
