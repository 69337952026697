import { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { AiOutlineDashboard, AiOutlineDollarCircle, AiOutlineSearch, AiOutlineCar, AiOutlineHeart, AiOutlineSetting } from "react-icons/ai";
import { BiWalletAlt, BiArrowBack, BiGitCompare } from "react-icons/bi";
import { BsTag, BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { MdOutlineFeedback, MdVideoSettings, MdLogout } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { FaBloggerB, FaUser, FaCar, FaMotorcycle, FaCartPlus, FaComment, FaBuilding, FaUserCircle } from "react-icons/fa";
import { urlMaker } from "../../../../helpers";
import { Auth } from "../../../../Context/Auth.context";
import { Data } from "../../../../Context/Data.context";
import { slideOutLeft, slideInLeft } from "react-animations";
import Radium, { StyleRoot } from "radium";
import "./Sidebar.scss";
import { CgMore } from "react-icons/cg";

function Sidebar({ setState }) {
  const categories = Data().categories;
  const { isAuthenticated, activateToken, activateAuthentication } = Auth();
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : [];

  const logout = () => {
    setState(false);
    setAnimateIn(false);
    activateAuthentication(false);
    activateToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("refreshCycle");
    localStorage.removeItem("fav");
  };

  const [animateIn, setAnimateIn] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const styles = {
    slideOutLeft: {
      animation: "x 0.6s",
      animationName: Radium.keyframes(slideOutLeft, "slideOutLeft"),
    },
    slideInLeft: {
      animation: "x 0.6s",
      animationName: Radium.keyframes(slideInLeft, "slideInLeft"),
    },
  };

  useEffect(() => {
    if (animateIn) {
      setTimeout(() => {
        setAnimateOut(true);
      }, 200);
    } else {
      setAnimateOut(false);
    }
  }, [animateIn]);

  return (
    <StyleRoot>
      <div id="mobile-sidebar">
        <div style={animateOut ? styles.slideInLeft : { display: "none" }}>
          <div className="container mb-3 py-3 pl-3 pr-0">
            <div className="d-flex">
              <BiArrowBack onClick={() => setAnimateIn(false)} className="text-secondary ml-1" style={{ fontSize: "1.5rem" }} />
              <div className="border-bottom w-100 ml-3 pb-3 mt-1 font-weight-bold text-dark">{user.name}</div>
            </div>
            <Link onClick={() => setState(false)} to="/user/profile" className="d-flex py-4 border-bottom">
              <FaUser className="text-dark ml-1" style={{ fontSize: "1.5rem" }} />{" "}
              <div className="text-dark ml-3 mt-n1 font-weight-bold">
                My Profile
                <div className="font-weight-light" style={{ fontSize: "12px" }}>
                  Edit your information
                </div>
              </div>
            </Link>
            <div className="py-4 border-bottom">
              <div className="text-dark mt-1 font-weight-bold">Sell Your Car at Best Price</div>
              <div className="text-muted mb-2" style={{ fontSize: "0.8rem" }}>
                Post your Ad for free in few easy steps.
              </div>
              <div className="mr-3">
                <Link to="/choose-category" className="btn btn-danger font-weight-bold w-100 mt-2">
                  + Post Your Ad
                </Link>
              </div>
            </div>
            <div className="py-3 border-bottom">
              {/* Dashboard */}
              <Link onClick={() => setState(false)} to="/user/dashboard" className="mt-2 card">
                <div className="card-header pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <AiOutlineDashboard />
                      <div className="align-self-center mt-1 ml-3">Dashboard</div>
                    </div>
                  </div>
                </div>
              </Link>

              {/* My Inventory */}
              <Link onClick={() => setState(false)} to="/user/my-inventory" className="mt-2 card">
                <div className="card-header pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <AiOutlineCar />
                      <div className="align-self-center mt-1 ml-3">My Inventory</div>
                    </div>
                  </div>
                </div>
              </Link>

              {/* Favorites */}
              <Link onClick={() => setState(false)} to="/user/my-favorites" className="mt-2 card">
                <div className="card-header pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <AiOutlineHeart />
                      <div className="align-self-center mt-1 ml-3">Favorites</div>
                    </div>
                  </div>
                </div>
              </Link>

              {/* Profile Setting */}
              <Link onClick={() => setState(false)} to="/user/profile" className="mt-2 card">
                <div className="card-header pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <AiOutlineSetting />
                      <div className="align-self-center mt-1 ml-3">My Profile</div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link onClick={() => setState(false)} to="/user/orders" className="mt-2 card">
                <div className="card-header pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <FaCartPlus />
                      <div className="align-self-center mt-1 ml-3">My Orders</div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link onClick={() => setState(false)} to="/user/messages" className="mt-2 card">
                <div className="card-header pl-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <FaComment />
                      <div className="align-self-center mt-1 ml-3">My Messages</div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="py-3">
              {/* Logout */}
              {isAuthenticated && (
                <a onClick={() => logout()} className="mt-2 card">
                  <div className="card-header pl-0">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <MdLogout />
                        <div className="align-self-center mt-1 ml-3">Logout</div>
                      </div>
                    </div>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
        <div style={animateOut ? { display: "none" } : animateIn ? styles.slideOutLeft : {}}>
          {/* Notification */}
          <div className="container mb-3">
            <div className="d-flex justify-content-end mt-2">
              <span className="nav-text px-2" style={{ marginTop: "6px" }}>
                CLOSE
              </span>
              <label className="switch">
                <input type="checkbox" onClick={() => setState(false)} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          {/* Login btn */}
          <div className="container">
            {isAuthenticated ? (
              <>
                <a onClick={() => setAnimateIn(true)} className="d-flex mb-4 text-decoration-none">
                  <div>
                    {user.image && user.image.length > 0 ? (
                      <img
                        src={user.image && user.image.includes("https://") ? `${user.image}` : `${process.env.REACT_APP_AWS_URL}${user.image}`}
                        className="rounded-circle shadow-sm"
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <FaUserCircle
                        style={{
                          height: "50px",
                          width: "50px",
                        }}
                      />
                    )}
                  </div>
                  <div className="ml-2 mt-1">
                    <div className="text-dark font-weight-bold" style={{ fontSize: "0.9rem" }}>
                      {user.name}
                    </div>
                    <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                      View Dashboard {">"}
                    </div>
                  </div>
                </a>
                <div className="py-4 border-top border-bottom">
                  <div className="text-dark font-weight-bold">Sell Your Car at Best Price</div>
                  <div className="text-muted mb-2" style={{ fontSize: "0.8rem" }}>
                    Post your Ad for free in few easy steps.
                  </div>
                  <div className="mr-3">
                    <Link to="/choose-category" className="btn btn-danger font-weight-bold w-100 mt-2">
                      + Post Your Ad
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="sign-text mb-2">Sign in for better experience</p>
                <div className="d-flex">
                  <Link to="/login" onClick={() => setState(false)} className="btn w-100 btn-sm btn-outline-danger px-3 py-1">
                    Login
                  </Link>
                  <Link to="/login?o=sign-up" onClick={() => setState(false)} className="btn w-100 btn-sm btn-danger px-3 py-1 ml-2">
                    Sign Up
                  </Link>
                </div>
              </>
            )}
          </div>
          {/* dropdown */}

          <Accordion>
            {/* New */}
            <Card className="mt-2">
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <BsTag />
                    <div className="align-self-center mt-1 ml-3">New</div>
                  </div>
                  <div>
                    <IoIosArrowDown className="mt-0" />
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {categories.map((category, idx) =>
                    category.category != "Parts" && category.category != "Car Rental" ? (
                      <div className="d-flex flex-wrap" key={idx}>
                        <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/new/${urlMaker(category.slug)}`}>
                          <div className="d-flex">
                            <img width="20px" height="20px" className="mr-2" src={`${process.env.REACT_APP_AWS_URL}${category.logo}`} />
                            <div>
                              <p className="mb-0">New {category.category}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/cars/comparison`}>
                      <div className="d-flex">
                        <FaCar size="20px" className="mr-2 mt-0" />
                        <div>
                          <p className="mb-0">New Cars Comparison</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/bikes/comparison`}>
                      <div className="d-flex">
                        <FaMotorcycle size="20px" className="mr-2 mt-0" />
                        <div>
                          <p className="mb-0">New Bikes Comparison</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/dealers/new`}>
                      <div className="d-flex">
                        <FaBuilding size="20px" className="mr-2 mt-0" />
                        <div>
                          <p className="mb-0">New Car Showrooms</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {/* Price */}
            <Card className="mt-2">
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <AiOutlineDollarCircle />
                    <div className="align-self-center mt-1 ml-3">Price</div>
                  </div>
                  <div>
                    <IoIosArrowDown className="mt-0" />
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {categories.map((category, idx) =>
                    category.category != "Parts" && category.category != "Car Rental" ? (
                      <div className="d-flex flex-wrap" key={idx}>
                        <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/price/${urlMaker(category.slug)}`}>
                          <div className="d-flex">
                            <img width="20px" height="20px" className="mr-2" src={`${process.env.REACT_APP_AWS_URL}${category.logo}`} />
                            <div>
                              <p className="mb-0">{category.category} Prices</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {/* Used */}
            <Card className="mt-2">
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <AiOutlineCar />
                    <div className="align-self-center mt-1 ml-3">Used Cars</div>
                  </div>
                  <div>
                    <IoIosArrowDown className="mt-0" />
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/search/cars/-/`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Used Cars for Sale</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/search/cars/-/ae_featured_1`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Featured Used Cars</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className="dropdown-item border-0 my-1" to={`/choose-category`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Sell your car</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className="dropdown-item border-0 my-1" to={`/dealers/used`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Used Car Dealers</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className="dropdown-item border-0 my-1" to={`/auction-sheet-verification`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Auction Sheet</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {/* Search */}
            <Card className="mt-2">
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <AiOutlineSearch />
                    <div className="align-self-center mt-1 ml-3">Search Vehicles</div>
                  </div>
                  <div>
                    <IoIosArrowDown className="mt-0" />
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  {categories.map(
                    (category, idx) =>
                      category.category != "Parts" && (
                        <div className="d-flex flex-lg-row flex-column" key={idx}>
                          <Link onClick={() => setState(false)} className="dropdown-item border-0 my-1" to={`/search/${urlMaker(category.slug)}/-/`}>
                            <div className="d-flex">
                              <img width="20px" height="20px" className="mr-2" src={`${process.env.REACT_APP_AWS_URL}${category.logo}`} />
                              <div>
                                <p style={{ fontWeight: "500" }} className="mb-0">
                                  Find {category.category == "Car Rental" ? "" : "Used"} {category.category}
                                  {category.category == "Car Rental" ? "s" : ""}{" "}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {/* Blog */}
            <a href="/blog" className="mt-2 card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <FaBloggerB />
                    <div className="align-self-center mt-1 ml-3">Automotive Updates</div>
                  </div>
                </div>
              </div>
            </a>

            {/* Videos */}
            <Link onClick={() => setState(false)} to="/videos" className="mt-2 card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <MdVideoSettings />
                    <div className="align-self-center mt-1 ml-3">Videos</div>
                  </div>
                </div>
              </div>
            </Link>

            {/* Packages */}
            <Link onClick={() => setState(false)} to="/packages" className="mt-2 card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <BiWalletAlt />
                    <div className="align-self-center mt-1 ml-3">Paid Plans</div>
                  </div>
                </div>
              </div>
            </Link>

            {/* Auction Sheet */}
            <Link onClick={() => setState(false)} to="/auction-sheet-verification" className="mt-2 card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <BsFileEarmarkSpreadsheet />
                    <div className="align-self-center mt-1 ml-3">Auction Sheet</div>
                  </div>
                </div>
              </div>
            </Link>

            {/* Logout */}
            {isAuthenticated && (
              <a onClick={() => logout()} className="mt-2 card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <MdLogout />
                      <div className="align-self-center mt-1 ml-3">Logout</div>
                    </div>
                  </div>
                </div>
              </a>
            )}

            {/* More */}
            <Card className="mt-2">
              <Accordion.Toggle as={Card.Header} eventKey="7">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <CgMore />
                    <div className="align-self-center mt-1 ml-3">More</div>
                  </div>
                  <div>
                    <IoIosArrowDown className="mt-7" />
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <div className="d-flex flex-wrap">
                    <a onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/blog`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Blog</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="d-flex flex-wrap">
                    <a onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/shop`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">New Bikes Comparison</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/about-us`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">About autoOnes</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/faqs`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">FAQ's</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/support`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Support</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/support/contact`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Contact Us</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/dmca`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">DMCA</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/write-for-us`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Write for us</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="d-flex flex-wrap">
                    <Link onClick={() => setState(false)} className=" dropdown-item border-0 my-1" to={`/our-author`}>
                      <div className="d-flex">
                        <div>
                          <p className="mb-0">Our Author</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          {/* footer */}
          <div className="container mt-5 mb-3">
            <p className="sign-text mb-2">Connect with us</p>
            <div className="socail-icons d-inline-flex ">
              <img className="img-fluid mr-2 " src="/Assests/facebook.png" />
              <img className="img-fluid mx-2 " src="/Assests/twitter.png" />
              <img className="img-fluid mx-2 " src="/Assests/youtube.png" />
              <img className="img-fluid mx-2 " src="/Assests/linkedin.png" />
              <img className="img-fluid mx-2 " src="/Assests/insta.png" />
            </div>
          </div>
        </div>
      </div>
    </StyleRoot>
  );
}

export default Sidebar;
