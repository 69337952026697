import { useState } from "react";
import { fallDown as Menu } from "react-burger-menu";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { FaTimes } from "react-icons/fa";
import "./styles.scss";

const Auth = ({ authVisible, hideAuth }) => {
  const [state, setState] = useState("signin");

  return (
    <div id="auth-burger-menu">
      <Menu isOpen={authVisible} onClose={hideAuth} left>
        <FaTimes className="close" onClick={hideAuth} />
        {state == "signin" && (
          <SignIn setState={setState} hideAuth={hideAuth} />
        )}
        {state == "signup" && (
          <SignUp hideAuth={hideAuth} setState={setState} />
        )}
      </Menu>
    </div>
  );
};

export default Auth;
