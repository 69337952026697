import React, { lazy } from "react";

/* Desktop & Mobile Optimized Routes */

// Home Desktop & Mobile View are same
const HomeM = lazy(() => import("../Mobile/Home"));

// Auction Sheet Desktop & Mobile Views
const VerifiedAS = lazy(() => import("../Views/AuctionSheet/Report"));
const VerificationAS = lazy(() => import("../Views/AuctionSheet/Mobile"));
const VerificationAS_M = lazy(() => import("../Views/AuctionSheet/Mobile"));
const CheckoutAS = lazy(() => import("../Views/AuctionSheet/Checkout"));
const CheckoutTranslation = lazy(() => import("../Views/AuctionSheet/Checkout/Translation"));
const SuccessAS = lazy(() => import("../Views/AuctionSheet/Success"));
const CustomAS = lazy(() => import("../Views/AuctionSheet/Custom"));

/* Desktop & Mobile Optimized Routes */

// Auth Desktop & Mobile Views
const Login = lazy(() => import("../Mobile/Auth/SignIn/"));
// const Login = lazy(() => import("../Views/User/Login.view.js"));
// Listing Desktop & Mobile Views
// const Listing = React.lazy(() => import("../Views/Listing/"));
const Listing = React.lazy(() => import("../Mobile/Listing/index"));

const Compare = React.lazy(() => import("../Views/Compare.view.js"));
const CompareM = React.lazy(() => import("../Mobile/Compare"));
const Search = React.lazy(() => import("../Views/Search/"));
const SearchM = React.lazy(() => import("../Mobile/Search"));
const SearchCategory = React.lazy(() => import("../Views/Search/Category.view"));
const Contact = React.lazy(() => import("../Views/Contact/Contact"));
const NewsRoom = React.lazy(() => import("../Views/Contact/Newsroom"));
const ContactSales = React.lazy(() => import("../Views/Contact/Sales"));
const HelpSupport = React.lazy(() => import("../Views/Contact/HelpSupport"));
const About = React.lazy(() => import("../Views/About.view.js"));
const PriceCategory = React.lazy(() => import("../Views/Prices/Category.view.js"));
const PriceFilters = React.lazy(() => import("../Views/Prices/PriceFilters.js"));
const PriceDetails = React.lazy(() => import("../Views/Prices/PriceDetails.js"));
const Packages = React.lazy(() => import("../Views/Packages/"));
const ASPackages = React.lazy(() => import("../Views/Packages/AuctionSheet/"));
const PackageSuccess = React.lazy(() => import("../Views/Packages/Success"));
// const Shop = React.lazy(() => import("../Views/Shop/Shop.js"));
// const Shop_M = React.lazy(() => import("../Mobile/Shop/"));
// const NewShop = React.lazy(() => import("../Views/Shop/NewShop.js"));
// const InsideShop = React.lazy(() => import("../Views/Shop/NewInsideShop.js"));
const InsideShop_M = React.lazy(() => import("../Mobile/Shop/Product/"));
const Video = React.lazy(() => import("../Views/Videos/Video.js"));
const Videos = React.lazy(() => import("../Views/Videos/Videos.js"));
const VideosCategory = React.lazy(() => import("../Views/Videos/Category.js"));
const NewCategory = React.lazy(() => import("../Views/New/Category"));
const New = React.lazy(() => import("../Views/New/New.js"));
const NewPrice = React.lazy(() => import("../Views/New/PriceSearch.js"));
const NewSearch = React.lazy(() => import("../Views/New/Search.js"));
const NewVariants = React.lazy(() => import("../Views/New/Variants"));
const NewInside = React.lazy(() => import("../Views/New/Inside"));
const New_M = React.lazy(() => import("../Mobile/New/New.js"));
const NewPrice_M = React.lazy(() => import("../Mobile/New/PriceSearch.js"));
const NewSearch_M = React.lazy(() => import("../Mobile/New/Search.js"));
const NewVariants_M = React.lazy(() => import("../Mobile/New/Variants"));
const NewInside_M = React.lazy(() => import("../Mobile/New/Inside"));
// const FAQs = React.lazy(() => import("../Views/FAQs/index.js"));
// const VinCheck = React.lazy(() => import("../Views/VinCheck/Outside.js"));
// const VinInside = React.lazy(() => import("../Views/VinCheck/Inside.js"));
const Dealers = React.lazy(() => import("../Views/Dealers/Dealers"));
const Dealers_M = React.lazy(() => import("../Mobile/Dealers/"));
const DealerInside = React.lazy(() => import("../Views/Dealers/DealerInside"));
const DealerDetails = React.lazy(() => import("../Mobile/Dealers/Details"));
const WriteReview = React.lazy(() => import("../Views/Reviews/Write"));
const WriteReview_M = React.lazy(() => import("../Mobile/Reviews"));
const WriteForUs = React.lazy(() => import("../Views/WriteForUs.js"));
const PrivacyPolicy = React.lazy(() => import("../Views/PrivacyPolicy"));
const TermsCondition = React.lazy(() => import("../Views/TermsCondition"));
const AdvertiseWithUs = React.lazy(() => import("../Views/AdvertiseWithUs.js"));
const DMCA = React.lazy(() => import("../Views/DMCA.js"));
const Author = React.lazy(() => import("../Views/Author.js"));

const VerifyAccountAPI = lazy(() => import("../API/VerifyAccount.api.js"));
const ResetPasswordAPI = lazy(() => import("../API/ResetPassword.api.js"));
// Prices
const FuelCostCalculator = React.lazy(() => import("../Views/Prices/FuelCostCalculator.js"));
const FuelPrices = React.lazy(() => import("../Views/Prices/FuelPrices.js"));

const WebsiteRoutes = [
  {
    id: "home",
    path: "/",
    exact: true,
    title: "Home",
    desktop: HomeM,
    mobile: HomeM,
  },

  /* Auction Sheet Routes */
  {
    exact: true,
    mobile: false,
    desktop: VerifiedAS,
    mobileHeader: "true",
    id: "verified-auction-sheet",
    title: "Verified Auction Sheet",
    path: "/verified-auction-sheet/:link",
  },
  {
    exact: true,
    id: "auction-sheet-verification",
    path: "/auction-sheet-verification",
    desktop: VerificationAS,
    mobile: VerificationAS_M,
  },
  {
    exact: true,
    id: "auction-sheet-verification",
    path: "/auction-sheet-verification/:chassis",
    desktop: VerificationAS,
    mobile: VerificationAS_M,
  },
  {
    exact: true,
    mobileHeader: "true",
    id: "auction-sheet-checkout",
    title: "Auction Sheet Checkout",
    path: "/auction-sheet/checkout",
    desktop: CheckoutAS,
    mobile: false,
  },
  {
    exact: true,
    mobileHeader: "true",
    id: "auction-sheet-translate",
    title: "Auction Sheet Translation Checkout",
    path: "/auction-sheet/translation/checkout",
    desktop: CheckoutTranslation,
    mobile: false,
  },
  {
    exact: true,
    mobileHeader: "true",
    id: "auction-sheet-success",
    title: "Thank You",
    path: "/auction-sheet/success/:id",
    desktop: SuccessAS,
    mobile: false,
  },
  {
    exact: true,
    id: "auction-sheet",
    path: "/auction-sheet/:url",
    desktop: CustomAS,
    mobile: false,
  },

  /* Auth Routes */
  {
    exact: true,
    mobileHeader: "true",
    id: "auth",
    path: "/login",
    desktop: Login,
    mobile: false,
  },

  /* Search Routes */
  {
    exact: true,
    id: "choose-search-category",
    title: "Choose Category",
    mobileHeader: true,
    path: "/search",
    desktop: Search,
    mobile: SearchCategory,
  },
  {
    exact: false,
    id: "search",
    path: "/search/:param/",
    desktop: Search,
    mobile: SearchM,
  },

  /* Listing Routes */
  {
    exact: true,
    id: "listing",
    path: "/used/:category/:url",
    desktop: Listing,
    mobile: Listing,
  },

  /* Price Routes */
  {
    exact: true,
    path: "/price",
    id: "price-category",
    title: "Choose Category",
    mobileHeader: false,
    desktop: PriceCategory,
    mobile: PriceCategory,
  },
  {
    title: "Prices",
    mobileHeader: false,
    exact: true,
    id: "price-brand",
    path: "/price/:param/:brand",
    desktop: PriceDetails,
    mobile: PriceDetails,
  },
  {
    title: "Prices",
    mobileHeader: false,
    exact: false,
    id: "price",
    path: "/price/:param",
    desktop: PriceFilters,
    mobile: PriceFilters,
  },

  /* New Routes */
  {
    exact: true,
    title: "New",
    id: "new-category",
    path: "/new",
    mobileHeader: true,
    desktop: NewCategory,
    mobile: NewCategory,
  },
  {
    exact: true,
    id: "new",
    path: "/new/:param",
    desktop: New,
    mobile: New_M,
  },
  {
    exact: true,
    id: "new-search",
    path: "/new/:param/search/",
    desktop: NewPrice,
    mobile: NewPrice_M,
  },
  {
    exact: true,
    id: "new-search",
    path: "/new/:param/:paramTwo",
    desktop: NewSearch,
    mobile: NewSearch_M,
  },
  {
    exact: true,
    id: "new-model",
    path: "/new/:param/:paramTwo/:paramThree",
    desktop: NewVariants,
    mobile: NewVariants_M,
  },
  {
    exact: true,
    id: "new-variant",
    path: "/new/:param/:paramTwo/:paramThree/:paramFour",
    desktop: NewInside,
    mobile: NewInside_M,
  },

  /* Packages Routes */
  {
    exact: true,
    id: "packages",
    path: "/packages",
    title: "Packages",
    mobileHeader: true,
    desktop: Packages,
    mobile: Packages,
  },
  /* AuctionSheet Packages Routes */
  {
    exact: true,
    id: "packages",
    path: "/packages/auction-sheet",
    title: "Packages",
    mobileHeader: true,
    desktop: ASPackages,
    mobile: ASPackages,
  },

  /* Videos Routes */
  {
    exact: true,
    id: "videos",
    path: "/videos",
    title: "Videos",
    desktop: Videos,
    mobile: Videos,
  },
  {
    exact: true,
    id: "videos-category",
    path: "/videos/:category",
    desktop: VideosCategory,
    mobile: VideosCategory,
  },
  {
    exact: true,
    id: "video",
    path: "/videos/:category/:video",
    desktop: Video,
    mobile: Video,
  },

  /* Comparison Routes */
  {
    exact: true,
    id: "comparison",
    path: "/:category/comparison",
    desktop: Compare,
    mobile: CompareM,
  },
  {
    id: "comparison-search",
    exact: false,
    path: "/:category/comparison/:title",
    desktop: Compare,
    mobile: CompareM,
  },

  /* Dealer Routes */
  {
    exact: true,
    id: "dealers",
    path: "/dealers/:type",
    mobileHeader: true,
    title: "Car Dealers",
    desktop: Dealers,
    mobile: Dealers_M,
  },
  {
    exact: true,
    id: "dealer",
    path: "/dealer/:username",
    desktop: DealerInside,
    mobile: DealerDetails,
  },

  /* Shop Routes */
  // {
  //   id: "shop",
  //   exact: true,
  //   path: "/shop",
  //   desktop: NewShop,
  //   mobile: Shop_M,
  // },
  // {
  //   exact: false,
  //   id: "shop-search",
  //   path: "/shop/search/",
  //   desktop: NewShop,
  //   mobile: Shop_M,
  // },
  // {
  //   exact: true,
  //   id: "shop-category",
  //   path: "/shop/:category/",
  //   desktop: Shop,
  //   mobile: Shop_M,
  // },
  // {
  //   exact: true,
  //   id: "shop-subcategory",
  //   path: "/shop/:category/:subcategory/",
  //   desktop: Shop,
  //   mobile: Shop_M,
  // },
  {
    exact: true,
    id: "product",
    path: "/product/:categry/:subcategory/:product",
    desktop: InsideShop_M,
    mobile: InsideShop_M,
  },

  /* Review Routes */
  {
    exact: true,
    id: "reviews",
    path: "/review/write/:id",
    desktop: WriteReview,
    mobile: WriteReview_M,
  },

  /* Static Routes */
  {
    exact: true,
    id: "contact-us",
    path: "/support",
    desktop: Contact,
    mobile: false,
  },
  {
    exact: true,
    id: "news-room",
    path: "/faqs",
    desktop: NewsRoom,
    mobile: NewsRoom,
  },
  {
    exact: true,
    id: "write-for-us",
    path: "/write-for-us",
    desktop: WriteForUs,
    mobile: WriteForUs,
  },
  {
    exact: true,
    id: "advertise-with-us",
    path: "/advertise-with-us",
    desktop: AdvertiseWithUs,
    mobile: AdvertiseWithUs,
  },
  {
    exact: true,
    id: "dmca",
    path: "/dmca",
    desktop: DMCA,
    mobile: DMCA,
  },
  {
    exact: true,
    id: "author",
    path: "/our-author",
    desktop: Author,
    mobile: Author,
  },
  {
    exact: true,
    id: "contact-sales",
    path: "/support/contact",
    desktop: ContactSales,
    mobile: ContactSales,
  },
  {
    exact: true,
    id: "contact-sales",
    path: "/support/chat",
    desktop: HelpSupport,
    mobile: HelpSupport,
  },
  {
    exact: true,
    id: "about-us",
    path: "/about-us",
    desktop: About,
    mobile: false,
  },

  {
    exact: true,
    id: "privacy-policy",
    path: "/privacy-policy",
    mobileHeader: true,
    title: "Privacy Policy",
    desktop: PrivacyPolicy,
    mobile: PrivacyPolicy,
  },
  {
    exact: true,
    id: "terms-conditions",
    path: "/terms-conditions",
    mobileHeader: true,
    title: "Terms & Condition",
    desktop: TermsCondition,
    mobile: TermsCondition,
  },

  {
    exact: true,
    id: "verify-account",
    path: "/verify-account",
    desktop: VerifyAccountAPI,
    mobile: false,
  },
  {
    exact: true,
    id: "reset-password",
    path: "/reset-password",
    desktop: ResetPasswordAPI,
    mobile: false,
  },
  {
    exact: true,
    id: "package",
    path: "/packages/success/:id",
    mobileHeader: false,
    desktop: PackageSuccess,
    mobile: PackageSuccess,
  },
  {
    exact: true,
    id: "fuelCostCalculator",
    path: "/fuel-cost-calculator",
    mobileHeader: false,
    desktop: FuelCostCalculator,
    mobile: false,
  },
  {
    exact: true,
    id: "fuelPrices",
    path: "/fuel-prices-in-pakistan",
    mobileHeader: false,
    desktop: FuelPrices,
    mobile: false,
  },
];

export default WebsiteRoutes;
