import { useHistory } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import "./styles.scss";

const MobilePageHeader = (props) => {
  return (
    <div id="mobile-page-header" className="border-bottom sticky-top pb-2">
      <div className="d-flex">
        <div className="justify-content-start">
          <MdArrowBack
            onClick={props.onClick}
            className="mt-1"
            style={{ fontSize: "1.4rem" }}
          />
        </div>
        <div className="ml-auto mr-auto" style={{ fontSize: "1.25rem" }}>
          {props.title}
        </div>
      </div>
    </div>
  );
};

MobilePageHeader.defaultProps = {
  onClick: () => window.history.go(-1),
  title: "AutoOnes",
};

export default MobilePageHeader;
