import React from "react";
import { AiFillStar } from "react-icons/ai";

const priceConvertor = (price, word = true, afterPoints = 2) => {
  let value = 0;
  if (price < 100000) {
    value = price;
  } else if (price >= 100000 && price < 10000000) {
    value = (price / 100000).toFixed(afterPoints) + " Lacs";
  } else if (price >= 10000000) {
    value = (price / 10000000).toFixed(afterPoints) + " Crore";
  }

  if (!word) value = value?.toString().replace("Lacs", "").replace("Crore", "");

  if (value?.toString().includes(".00")) {
    value = value.replace(".00", "");
  }

  // Returns the price
  return value?.toString();
};

const urlMaker = (param) => {
  return param
    ? `${param
        .replace(/[ &\/\\#,+()@%^!$~%.'":*?<>{}]/g, "-")
        .replace(/-+/g, "-")
        .toLowerCase()}`
    : "";
};

const shortWordMaker = (param) => {
  return param.charAt(0).toLowerCase() + param.charAt(param.length - 2).toLowerCase();
};

const checkedOrNot = (param, id, query) => {
  const line = param.charAt(0).toLowerCase() + param.charAt(param.length - 2).toLowerCase() + "=" + id;
  return query && query.includes(line);
};

const longWordMaker = (param) => {
  switch (param) {
    case "mr":
      return "Makers";
      break;
    case "ml":
      return "Models";
      break;
    case "vt":
      return "Variants";
      break;
    case "cr":
      return "Colors";
      break;
    case "se":
      return "States";
      break;
    case "sr":
      return "SubCategory";
      break;
    case "ce":
      return "Cities";
      break;
    case "aa":
      return "Areas";
      break;
    case "re":
      return "Registration Cities";
      break;
    case "be":
      return "Body Types";
      break;
    case "al":
      return "Assembly";
      break;
    case "sp":
      return "seller Type";
      break;
    case "ae":
      return "Ad Types";
      break;
    case "minP":
      return "Min. Price";
      break;
    case "maxP":
      return "Max. Price";
      break;
    case "minY":
      return "Min. Year";
      break;
    case "maxY":
      return "Max. Year";
      break;
    case "str":
      return "Search";
      break;
  }
};

const stars = (limit) => {
  var rows = [];
  for (var i = 1; i <= limit; i++) {
    rows.push(<AiFillStar color="orange" size={20} key={i} />);
  }

  for (var i = rows.length; i < 5; i++) {
    rows.push(<AiFillStar color="grey" size={20} key={i} />);
  }

  return <span>{rows}</span>;
};

export { priceConvertor, urlMaker, shortWordMaker, checkedOrNot, longWordMaker, stars };
