import Axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { toast } from "react-toastify";
import { Auth } from "../../Context/Auth.context";
import { config } from "../../config/social.config";
import { useHistory } from "react-router-dom";

const Facebook = ({ dealer }) => {
  const auth = Auth();
  const history = useHistory();

  const FacebookLoginButton = () => {
    return (
      <FacebookLogin
        appId={config.fb_Id}
        callback={facebookResponse}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className="btn py-3 mb-4 btn-outline-danger w-100 rounded-pill text-dark"
          >
            <img
              style={{ height: "20px", width: "auto" }}
              src="/Assests/facebook.svg"
            />{" "}
            Continue With Facebook
          </button>
        )}
      />
    );
  };

  const facebookResponse = (res) => {
    Axios.post(`${process.env.REACT_APP_API_URL}auth/social/facebook`, {
      token: res.accessToken,
      type: dealer ? 2 : 1,
    }).then((res) => {
      if (res.data.result == 2) {
        toast.error("Account with this email already exists!");
      } else {
        /* Successful Login */
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.result));
        auth.activateToken(localStorage.setItem("token", res.data.token));
        auth.activateAuthentication(true);
        history.push("/user/my-inventory");
      }
    });
  };

  return <FacebookLoginButton />;
};

export default Facebook;
