import React, { lazy } from "react";

const Dashboard = lazy(() => import("../Views/User/Dashboard"));
const Inventory = lazy(() => import("../Views/User/Inventory"));
const MyInventory = lazy(() => import("../Mobile/User/MyInventory"));
const Favorites = lazy(() => import("../Views/User/Favourites"));
const MyFavorite = lazy(() => import("../Mobile/User/MyFavorite"));
const Profile = lazy(() => import("../Views/User/Profile"));
const MyProfile = lazy(() => import("../Mobile/User/Profile"));
const Orders = lazy(() => import("../Views/User/Orders"));
const Reports = lazy(() => import("../Views/User/Reports"));
const ChooseCategory = lazy(() => import("../Views/Add/Category.view"));
const MyMessages = lazy(() => import("../Views/User/Messages"));
// const PostAd = lazy(() => import("../Views/Add/Form.view"));
const PostAd = lazy(() => import("../Mobile/PostAd/"));
const EditAd = lazy(() => import("../Mobile/EditAd/"));
const PackagesCheckout = lazy(() => import("../Views/Packages/Checkout"));

const ProtectedRoutes = [
  {
    exact: true,
    id: "dashboard",
    path: "/user/dashboard",
    title: "My Ads",
    mobileHeader: true,
    desktop: Dashboard,
    mobile: MyInventory,
  },
  {
    exact: true,
    id: "my-inventory",
    path: "/user/my-inventory",
    title: "My Ads",
    mobileHeader: true,
    desktop: Inventory,
    mobile: MyInventory,
  },
  {
    exact: true,
    id: "saved-ads",
    path: "/user/my-favorites",
    title: "Saved Ads",
    mobileHeader: true,
    desktop: Favorites,
    mobile: MyFavorite,
  },
  {
    exact: true,
    id: "my-profile",
    path: "/user/profile",
    title: "My Profile",
    mobileHeader: true,
    desktop: Profile,
    mobile: MyProfile,
  },
  {
    exact: true,
    id: "my-messages",
    path: "/user/messages",
    title: "My Messages",
    mobileHeader: false,
    desktop: MyMessages,
    mobile: MyMessages,
  },
  {
    exact: true,
    id: "my-orders",
    path: "/user/orders",
    title: "My Orders",
    mobileHeader: true,
    desktop: Orders,
    mobile: Orders,
  },
  {
    exact: true,
    id: "my-reports",
    path: "/user/reports",
    title: "My Reports",
    mobileHeader: true,
    desktop: Reports,
    mobile: Reports,
  },
  {
    exact: true,
    id: "choose-category",
    title: "Post An Ad",
    mobileHeader: true,
    path: "/choose-category",
    desktop: ChooseCategory,
    mobile: ChooseCategory,
  },
  {
    exact: true,
    id: "post",
    title: "Post An Ad",
    mobileHeader: true,
    path: "/add/:category",
    desktop: PostAd,
    mobile: false,
  },
  {
    exact: true,
    id: "post",
    title: "Edit Your Ad",
    mobileHeader: true,
    path: "/edit/:category/:id",
    desktop: EditAd,
    mobile: false,
  },
  {
    exact: true,
    id: "package",
    title: "Payment & Information",
    path: "/packages/:type/:id",
    mobileHeader: true,
    desktop: PackagesCheckout,
    mobile: PackagesCheckout,
  },
];

export default ProtectedRoutes;
