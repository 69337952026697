import { AuthProvider } from "./Context/Auth.context";
import { DataProvider } from "./Context/Data.context";
import { ToastContainer } from "react-toastify";
import { loadProgressBar } from "x-axios-progress-bar";
import Routes from "./Routes";
import ErrorBound from "./ErrorBoundry";

function App() {
  loadProgressBar();

  return (
    <DataProvider>
      <AuthProvider>
        <ErrorBound>
          <Routes />
          <ToastContainer />
        </ErrorBound>
      </AuthProvider>
    </DataProvider>
  );
}

export default App;
