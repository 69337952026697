import { useRef, lazy } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin, faFacebook, faInstagram, faPinterest, faYoutube } from "@fortawesome/free-brands-svg-icons";
import MobileMenu from "./MobileMenu";
import Newsletter from "../Newsletter";
import NewsletterService from "../../Services/Newsletter";
import AdSlots from "../AdSlots/";

const Footer = () => {
  const email = useRef();
  const button = useRef();
  const message = useRef();

  const submit = (e) => {
    e.preventDefault();

    NewsletterService.create(email.current.value).then((res) => {
      const { data } = res;

      button.current.innerHTML = "Subscribed";
      message.current.innerHTML = "You have Succesfully Subscribed";
    });
  };

  return (
    <div id="autoOnes-footer">
      {/* <div className={`mb-4 ${window.innerWidth > 480 ? "mx-5" : "container"}`}>{window.innerWidth > 480 ? <AdSlots page="Footer Desktop" /> : <AdSlots page="Footer Mobile" />}</div> */}

      {window.innerWidth < 500 && (
        <>
          <Newsletter />
          <MobileMenu />
        </>
      )}
      {window.innerWidth > 500 ? (
        <div className="footer bg-light">
          <Container fluid className="mt-sm-5">
            <Row>
              <Col lg={1}></Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="text-dark font-weight-bold">Cars By City</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Car for sale in Lahore</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Islamabad</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Karachi</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Faisalabad</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Rawalpindi</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Multan</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Hyderabad</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Gujranwala</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Peshawar</Link>
                  </li>
                  <li>
                    <Link to="/">Car for sale in Sialkot</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="text-dark font-weight-bold">Cars By Category</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Small Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Cheap Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Low Price Cars</Link>
                  </li>
                  <li>
                    <Link to="/">Japanese Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Automatic Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">1300cc Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">1000cc Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">600cc Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Hybrid Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Cars Under 5 Lakh</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="text-dark font-weight-bold">Car Prices</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Honda Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Daihatsu Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Nissan Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">KIA Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Hyundai Car Prices</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="text-dark font-weight-bold">Popular Models</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Honda Civi for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Alto for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Cultus for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Honda City for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Corolla for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Yaris for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Changan Alsvin for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Wagon R for sale</Link>
                  </li>
                  <li>
                    <Link to="/">KIA Picanto for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Fortuner for sale</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={6} xs={12}>
                <h6 className="text-dark font-weight-bold">Subscribe to our Newsletter</h6>
                <form onSubmit={submit}>
                  <Row className="">
                    <Col md={8} xs={7} className="px-0">
                      <input required type="email" ref={email} style={{ padding: "17px" }} className="form-control form-control-sm " placeholder="mail@autoOnes.com" />
                    </Col>
                    <Col md={4} xs={3} className="px-0">
                      <button ref={button} type="submit" className="btn-custom rounded-0 px-1">
                        Subscribe
                      </button>
                    </Col>
                  </Row>
                  <small ref={message}></small>
                </form>
                <div className="mt-md-5 mt-3">
                  <h6 className="text-dark font-weight-bold">Follow us</h6>
                  <Row>
                    <Col lg={2} md={2} xs={2}>
                      <a href="https://web.facebook.com/autoOnes/" className="social-icons">
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <a href="https://www.instagram.com/auto.ones/" className="social-icons">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <a href="https://www.pinterest.com/autoOnes/" className="social-icons">
                        <FontAwesomeIcon icon={faPinterest} />
                      </a>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <a href="https://twitter.com/auto_ones" className="social-icons">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <a href="https://www.linkedin.com/in/autoones-com/" className="social-icons">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <a href="https://www.youtube.com/channel/UCHHMbMnBsBPRrdqpwb0vclA" className="social-icons">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={1}></Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-4 text-dark font-weight-bold">Cars By Brands</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Honda Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Daihatsu Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Nissan Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">KIA Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Hyundai Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">MG Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Mitsubishi Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Mercedes Benz Cars for sale</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-4 text-dark font-weight-bold">Useful Links</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/auction-sheet-verification">Auction Sheet</Link>
                  </li>
                  <li>
                    <Link to="/">Used Cars</Link>
                  </li>
                  <li>
                    <Link to="/">Used Bikes</Link>
                  </li>
                  <li>
                    <Link to="/">New Cars</Link>
                  </li>
                  <li>
                    <Link to="/">New Bikes</Link>
                  </li>
                  <li>
                    <Link to="/">Car Comparison</Link>
                  </li>
                  <li>
                    <Link to="/">Used Rikshaws</Link>
                  </li>
                  <li>
                    <Link to="/">Sell Car Online</Link>
                  </li>
                  <li>
                    <Link to="/">Sell Bike Online</Link>
                  </li>
                  <li>
                    <Link to="/">Sell Parts Online</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-4 text-dark font-weight-bold">Cars By Body Type</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Sedan Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Hatchback Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Used SUVs Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Crossover Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Used Van Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Off Road Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Convertible Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Used Trucks Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Double Cabin Cars for sale</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-4 text-dark font-weight-bold">autoOnes.com</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/support/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/dmca">DMCA</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About autoOnes</Link>
                  </li>
                  <li>
                    <Link to="/write-for-us">Write for Us</Link>
                  </li>
                  <li>
                    <Link to="/advertise-with-us">Advertise With Us</Link>
                  </li>
                  <li>
                    <Link to="/">Careers</Link>
                  </li>
                  <li>
                    <Link to="/dealers/used">Used Car Dealers</Link>
                  </li>
                  <li>
                    <Link to="/dealers/new">Car Showrooms</Link>
                  </li>
                </ul>
              </Col>
            </Row>
            <hr />
            <div className="mt-3">
              <div className="text-center">
                <div className="text-dark">Copyright © 2018 - {new Date().getFullYear()} AutoOnes (Pvt) Ltd. - All Rights Reserved.</div>
                <div className="text-dark">
                  <Link to="/terms-conditions" className="text-dark">
                    Terms of Service
                  </Link>{" "}
                  |{" "}
                  <Link to="/privacy-policy" className="text-dark">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div className="footer bg-light mb-5 pb-4">
          <Container fluid className="mt-sm-5">
            <Row>
              <Col lg={1}></Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="text-dark font-weight-bold">Cars By City</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Car for sale in Lahore</Link>
                  </li>
                  <li>
                    <Link to="/" className="text-overflow">
                      Car for sale in Islamabad
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-overflow">
                      Car for sale in Karachi
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-overflow">
                      Car for sale in Faisalabad
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="text-overflow">
                      Car for sale in Rawalpindi
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="text-dark font-weight-bold">Cars By Category</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Small Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Cheap Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Low Price Cars</Link>
                  </li>
                  <li>
                    <Link to="/">Japanese Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Automatic Cars for sale</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-2 text-dark font-weight-bold">Car Prices</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Honda Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Daihatsu Car Prices</Link>
                  </li>
                  <li>
                    <Link to="/">Nissan Car Prices</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-2 text-dark font-weight-bold">Popular Models</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Honda Civi for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Alto for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Cultus for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Honda City for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Corolla for sale</Link>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col lg={1}></Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-2 text-dark font-weight-bold">Cars By Brands</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Honda Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Toyota Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Suzuki Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Daihatsu Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Nissan Cars for sale</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-2 text-dark font-weight-bold">Useful Links</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/auction-sheet-verification">Auction Sheet</Link>
                  </li>
                  <li>
                    <Link to="/">Used Cars</Link>
                  </li>
                  <li>
                    <Link to="/">Used Bikes</Link>
                  </li>
                  <li>
                    <Link to="/">New Cars</Link>
                  </li>
                  <li>
                    <Link to="/">New Bikes</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-2 text-dark font-weight-bold " style={{ whiteSpace: "nowrap" }}>
                  Cars By Body Type
                </h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/">Sedan Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Hatchback Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">SUVs Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Crossover Cars for sale</Link>
                  </li>
                  <li>
                    <Link to="/">Used Van Cars for sale</Link>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <h6 className="mt-2 text-dark font-weight-bold">autoOnes.com</h6>
                <ul className="list-unstyled text-muted">
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/our-author">Our Authors</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/dmca">DMCA</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About autoOnes</Link>
                  </li>
                  <li>
                    <Link to="/write-for-us">Write for Us</Link>
                  </li>
                </ul>
              </Col>
            </Row>
            <h6 className="mt-2 text-dark font-weight-bold">Subscribe to our Newsletter</h6>
            <form onSubmit={submit} className="ml-3">
              <Row className="">
                <Col md={8} xs={7} className="px-0">
                  <input required type="email" ref={email} style={{ padding: "16px" }} className="form-control form-control-sm " placeholder="mail@autoOnes.com" />
                </Col>
                <Col md={4} xs={3} className="px-0">
                  <button ref={button} type="submit" className="btn rounded py-1 px-4" style={{ backgroundColor: "#AA2625", color: "white" }}>
                    Subscribe
                  </button>
                </Col>
              </Row>
              <small ref={message}></small>
            </form>
            <Col lg={2} md={6} xs={12} className="px-1">
              <div className="mt-md-5 mt-3">
                <h6 className="text-dark font-weight-bold">Follow us</h6>
                <Row>
                  <Col lg={2} md={2} xs={2}>
                    <a href="https://web.facebook.com/autoOnes/" className="social-icons">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </Col>
                  <Col lg={2} md={2} xs={2}>
                    <a href="https://www.instagram.com/auto.ones/" className="social-icons">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </Col>
                  <Col lg={2} md={2} xs={2}>
                    <a href="https://www.pinterest.com/autoOnes/" className="social-icons">
                      <FontAwesomeIcon icon={faPinterest} />
                    </a>
                  </Col>
                  <Col lg={2} md={2} xs={2}>
                    <a href="https://twitter.com/auto_ones" className="social-icons">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </Col>
                  <Col lg={2} md={2} xs={2}>
                    <a href="https://www.linkedin.com/in/autoones-com/" className="social-icons">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </Col>
                  <Col lg={2} md={2} xs={2}>
                    <a href="https://www.youtube.com/channel/UCHHMbMnBsBPRrdqpwb0vclA" className="social-icons">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <hr />
            <div className="mt-3">
              <div className="text-center">
                <div className="text-dark">Copyright © 2018 - {new Date().getFullYear()} AutoOnes (Pvt) Ltd. - All Rights Reserved.</div>
                <div className="text-dark">
                  <Link to="/terms-conditions" className="text-dark">
                    Terms of Service
                  </Link>{" "}
                  |{" "}
                  <Link to="/privacy-policy" className="text-dark">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Footer;
