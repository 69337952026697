import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [scroll, setScroll] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, [pathname]);

  const toTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleNavigation = (e) => {
    if (window.scrollY > 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const scrollerStyle = {
    position: "fixed",
    bottom: 10,
    right: 10,
    borderRadius: "10px",
    backgroundColor: "#AA2625",
    cursor: "pointer",
    color: "#fff",
    fontSize: "20px",
    zIndex: "1",
  };

  return (
    <>
      {scroll && (
        <div className="scroller" style={scrollerStyle} onClick={toTop}>
          <FontAwesomeIcon icon={faArrowUp} style={{ margin: "15px 15px 10px 15px" }} />
        </div>
      )}
    </>
  );
}
