import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";

const DataContext = createContext({});

const DataProvider = (props) => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);

  /* Get Categories & Cities */
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}category`).then((res) => {
      setCategories(res.data);
      setCategory(res.data[0]);
    });
  }, []);

  const dataContextValue = {
    category,
    categories,
    setCategory,
  };
  return <DataContext.Provider value={dataContextValue} {...props} />;
};

const Data = () => React.useContext(DataContext);

export { DataProvider, Data };
