import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FaAdversal } from "react-icons/fa";
import { urlMaker } from "../../helpers";

const AdSlots = ({ page }) => {
  const [ad, setAd] = useState({});

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/advertisment/page/${page}`).then((res) => {
      const { data } = res;
      setAd(data?.[0] || {});

      if (data[0] && data[0].code) {
        document.getElementById(urlMaker(page)).innerHTML = data[0].code;
        var adsbygoogle;
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    });
  }, [page]);

  return (
    <div className="ad-slots">
      {ad.code ? (
        <div id={urlMaker(page)}></div>
      ) : ad.img ? (
        <img style={{ width: "100%", height: "auto", objectFit: "contain" }} src={process.env.REACT_APP_AWS_URL + ad.image} />
      ) : (
        <div className="border">
          <h6 className="text-center text-muted align-self-center p-5">
            <FaAdversal />
          </h6>
        </div>
      )}
    </div>
  );
};

export default AdSlots;
