import { useRef } from "react";
import NewsletterService from "../../Services/Newsletter/";
import "./styles.scss";

const Newsletter = ({ background }) => {
  const email = useRef();
  const button = useRef();
  const message = useRef();

  const submit = (e) => {
    e.preventDefault();

    NewsletterService.create(email.current.value).then((res) => {
      const { data } = res;

      button.current.innerHTML = "Subscribed";
      message.current.innerHTML = "You have Succesfully Subscribed";
    });
  };

  return (
    <form onSubmit={submit}>
      <div id="newsletter-mobile">
        <div className={`conatiner ${background ? `newslatter-padding` : `bg-light`} p-3 `}>
          <h4 className="mb-3">Subscribe to our newsletter</h4>
          <p className="text-newsletter">Singup for our weekly newsletter to get the latest news, updates and amazing offers delivered directly in your inbox.</p>
          <div className="d-flex">
            <input required type="email" ref={email} className="input-newsletter w-100 pl-2 pr-4 p-2 bg-light" placeholder="Enter your email" />
            <button ref={button} type="submit" className="btn px-3 py-1 ml-2 btn-size rounded-0">
              Subscribe
            </button>
          </div>
          <small ref={message}></small>
        </div>
      </div>
    </form>
  );
};

export default Newsletter;
