import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { urlMaker } from "../../helpers.js";
import { BottomSheet } from "react-spring-bottom-sheet";

const TopBar = ({ categories }) => {
  const [newOpen, setNewOpen] = useState(false);
  const [priceOpen, setPriceOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [shopOpen, setShopOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  const [newBottom, setNewBottom] = useState(false);
  const [priceBottom, setPriceBottom] = useState(false);

  return (
    <div className="topbar-v3 mt-n2 d-flex">
      <Dropdown show={searchOpen} onMouseEnter={() => setSearchOpen(true)} onMouseLeave={() => setSearchOpen(false)}>
        <Dropdown.Toggle className="py-2">
          <Link to="/search">Used Vehicles</Link>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menubar">
          {categories.map((category, idx) => (
            <div className="d-flex flex-lg-row flex-column" key={idx}>
              <Link className="dropdown-item border-0" to={`/search/${urlMaker(category.slug)}/-/`}>
                <div className="mx-3 my-2">
                  Find {category.category == "Car Rental" ? "" : "Used"} {category.category}
                  {category.category == "Car Rental" ? "s" : ""}{" "}
                </div>
              </Link>
            </div>
          ))}
          <div className="d-flex flex-lg-row flex-column">
            <Link className="dropdown-item border-0" to={`/dealers/used`}>
              <div className="mx-3 my-2">Used Car Dealers</div>
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown show={newOpen} onMouseEnter={() => setNewOpen(true)} onMouseLeave={() => setNewOpen(false)}>
        <Dropdown.Toggle onClick={() => setNewBottom(true)} className="py-2">
          <a>New Vehicles</a>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menubar">
          {categories.map((category, idx) =>
            category.category != "Parts" && category.category != "Car Rental" ? (
              <div className="d-flex flex-wrap" key={idx}>
                <Link className="dropdown-item border-0" to={`/new/${urlMaker(category.slug)}`}>
                  <div className="mx-3 my-2">Find New {category.category}</div>
                </Link>
              </div>
            ) : (
              ""
            )
          )}
          <div className="d-flex flex-lg-row flex-column">
            <Link className="dropdown-item border-0" to={`/cars/comparison`}>
              <div className="mx-3 my-2">New Cars Comparison</div>
            </Link>
          </div>
          <div className="d-flex flex-lg-row flex-column">
            <Link className="dropdown-item border-0" to={`/bikes/comparison`}>
              <div className="mx-3 my-2">New Bikes Comparison</div>
            </Link>
          </div>
          <div className="d-flex flex-lg-row flex-column">
            <Link className="dropdown-item border-0" to={`/dealers/new`}>
              <div className="mx-3 my-2">New Car Showroom</div>
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown show={priceOpen} onMouseEnter={() => setPriceOpen(true)} onMouseLeave={() => setPriceOpen(false)}>
        <Dropdown.Toggle onClick={() => setPriceBottom(true)} className="py-2">
          <Link to="/price">Prices</Link>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menubar">
          {categories.map((category, idx) =>
            category.category != "Parts" && category.category != "Car Rental" ? (
              <div className="d-flex flex-wrap" key={idx}>
                <Link className=" dropdown-item border-0" to={`/price/${urlMaker(category.slug)}`}>
                  <div className="mx-3 my-2">Find {category.category} Prices</div>
                </Link>
              </div>
            ) : (
              ""
            )
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown show={shopOpen} onMouseEnter={() => setShopOpen(true)} onMouseLeave={() => setShopOpen(false)}>
        <Dropdown.Toggle className="py-2">
          <Link to="/search/parts/-/">Parts</Link>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menubar">
          <a className="dropdown-item border-0 pl-3" href={`/shop`}>
            <div className="mx-3 my-2">New Parts & Accessories</div>
          </a>
          <Link className="dropdown-item border-0 pl-3" to={`/search/parts/-/`}>
            <div className="mx-3 my-2">Find Auto Parts</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/choose-category`}>
            <div className="mx-3 my-2">Sell Your Parts</div>
          </Link>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown show={moreOpen} onMouseEnter={() => setMoreOpen(true)} onMouseLeave={() => setMoreOpen(false)}>
        <Dropdown.Toggle className="py-2">
          <a>More</a>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menubar">
          <a className="dropdown-item border-0 pl-3" href={`/blog`}>
            <div className="mx-3 my-2">Blog</div>
          </a>
          <Link className="dropdown-item border-0 pl-3" to={`/videos`}>
            <div className="mx-3 my-2">Vidoes</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/packages`}>
            <div className="mx-3 my-2">Ads Packages</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/packages/auction-sheet`}>
            <div className="mx-3 my-2">Auction Sheet Packages</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/auction-sheet-verification`}>
            <div className="mx-3 my-2">Auction Sheet</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/fuel-prices-in-pakistan`}>
            <div className="mx-3 my-2">Fuel Prices</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/fuel-cost-calculator`}>
            <div className="mx-3 my-2">Mileage Calculator</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/about-us`}>
            <div className="mx-3 my-2">About autoOnes</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/faqs`}>
            <div className="mx-3 my-2">FAQ's</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/support`}>
            <div className="mx-3 my-2">Support</div>
          </Link>
          <Link className="dropdown-item border-0 pl-3" to={`/support/contact`}>
            <div className="mx-3 my-2">Contact Us</div>
          </Link>
        </Dropdown.Menu>
      </Dropdown>

      <BottomSheet open={newBottom} onDismiss={() => setNewBottom(false)}>
        <div className="py-3">
          <div className="container">
            <h5>Select Category For Search</h5>
            <div className="row no-gutters">
              {categories &&
                categories.map(
                  (category, i) =>
                    category.category !== "Parts" &&
                    category.category !== "Car Rental" && (
                      <div onClick={() => setNewBottom(false)} className="col-2">
                        <Link
                          to={{
                            pathname: `/new/${category.slug}/`,
                          }}
                        >
                          <div className="border border-danger rounded p-2 m-2">
                            <div className="d-flex justify-content-center">
                              <img
                                src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            </div>
                            <div className="text-dark text-center text-overflow">{category.category}</div>
                          </div>
                        </Link>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </BottomSheet>

      <BottomSheet open={priceBottom} onDismiss={() => setPriceBottom(false)}>
        <div className="py-3">
          <div className="container">
            <h5>Select Category For Price</h5>
            <div className="row no-gutters">
              {categories &&
                categories.map(
                  (category, i) =>
                    category.category != "Parts" &&
                    category.category != "Car Rental" && (
                      <div onClick={() => setPriceBottom(false)} className="col-2">
                        <Link
                          to={{
                            pathname: `/price/${category.slug}/`,
                          }}
                        >
                          <div className="border border-danger rounded p-2 m-2">
                            <div className="d-flex justify-content-center">
                              <img
                                src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            </div>
                            <div className="text-dark text-center text-overflow">{category.category}</div>
                          </div>
                        </Link>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default TopBar;
