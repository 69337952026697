import { useState, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Auth } from "../Context/Auth.context";
import WebsiteRoutes from "./WebsiteRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import PageLoader from "../Loaders/Page.loader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";
import MobilePageHeader from "../Components/Mobile/Header";
import SEO from "../SEO/SEO";

const Routes = () => {
  const { isAuthenticated } = Auth();

  const mobileSize = 500;
  const routes = WebsiteRoutes;
  const protectedRoutes = ProtectedRoutes;

  const [size, setSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setSize(window.innerWidth);
  });

  return (
    <Router>
      <SEO />
      <ScrollToTop />
      {size > mobileSize && <Header />}
      <Switch>
        {routes &&
          routes.map((route, i) => (
            <Route
              exact={route.exact}
              path={route.path}
              key={route.id}
              render={(props) => (
                <div id={route.id}>
                  <Suspense fallback={<PageLoader />}>
                    {size < mobileSize && route.mobile ? (
                      <>
                        {route.mobileHeader && (
                          <MobilePageHeader title={route.title} />
                        )}
                        <route.mobile {...props} />
                      </>
                    ) : (
                      <route.desktop {...props} />
                    )}
                  </Suspense>
                </div>
              )}
            />
          ))}

        {isAuthenticated ? (
          protectedRoutes &&
          protectedRoutes.map((route, i) => (
            <Route
              exact={route.exact}
              path={route.path}
              key={route.id}
              render={(props) => (
                <div id={route.id}>
                  <Suspense fallback={<PageLoader />}>
                    {size < mobileSize && route.mobile ? (
                      <>
                        {route.mobileHeader && (
                          <MobilePageHeader title={route.title} />
                        )}
                        <route.mobile {...props} />
                      </>
                    ) : (
                      <route.desktop {...props} />
                    )}
                  </Suspense>
                </div>
              )}
            />
          ))
        ) : (
          <Redirect to="/login" />
        )}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
