import { useState } from "react";
import { Link } from "react-router-dom";
import { FaRegEnvelope, FaPhoneAlt, FaArrowLeft } from "react-icons/fa";
import Email from "./Email/SignUp";
import Facebook from "./facebook";
import Google from "./google";

const SignUp = ({ setState, hideAuth }) => {
  const [display, setDisplay] = useState(false);

  return (
    <div id="sign-sidebar" className="d-flex">
      <div style={{ zIndex: 1000 }} className="back" onClick={() => setState("signin")}>
        <FaArrowLeft /> Back
      </div>
      <div className="d-flex align-items-center w-100 ">
        <div className="px-5 w-100 " style={{ marginTop: display ? "200px" : "200px", height: "100vh" }}>
          <h2>Create an account</h2>
          {display ? (
            <div className="align-self-center d-flex">
              <Email />
            </div>
          ) : (
            <>
              <div className="social-btn">
                <Facebook />
                <Google />
                {/* <button className="btn py-3 mb-4 btn-outline-danger w-100 rounded-pill text-dark">
                  <FaPhoneAlt /> Continue With Mobile
                </button> */}
                <button onClick={() => setDisplay(true)} className="btn py-3 mb-4 btn-outline-danger w-100 rounded-pill text-dark">
                  <FaRegEnvelope /> Continue With Email
                </button>
                <p>
                  By continuing, you agree to our <Link to="/terms-conditions">Terms & Conditons</Link> and <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
              </div>
            </>
          )}
          <p>
            Forget Password?{" "}
            <Link to="/login?f=forget-password" className="text-danger m-0" onClick={hideAuth}>
              Reset Now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
