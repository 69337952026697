import React from "react";
import Axios from "axios";
import { api } from "../../config/app.config";

const Newsletter = {};

Newsletter.create = async (email) => {
  return await Axios.post(`${api}newsletter/`, { email });
};

export default Newsletter;
