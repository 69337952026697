import Axios from "axios";
import { toast } from "react-toastify";
import { Auth } from "../../Context/Auth.context";
import { config } from "../../config/social.config";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";

const Google = ({ dealer }) => {
  const auth = Auth();
  const history = useHistory();

  const GoogleLoginButton = () => {
    return (
      <GoogleLogin
        clientId={config.googleC_Id}
        render={(renderProps) => (
          <button onClick={renderProps.onClick} className="btn py-3 mb-4 btn-outline-danger w-100 rounded-pill text-dark">
            <img style={{ height: "20px", width: "auto" }} src="/Assests/google.svg" /> Continue With Google
          </button>
        )}
        responseType="code"
        uxMode="popup"
        buttonText="Login"
        onSuccess={googleResponse}
        onFailure={() => {
          console.error("Login with google failed!");
        }}
        cookiePolicy={"single_host_origin"}
      />
    );
  };

  const googleResponse = (res) => {
    if (res.error && res.error === "popup_closed_by_user") {
      toast.error("Pop Up blocked on your browser!");
    } else {
      Axios.post(`${process.env.REACT_APP_API_URL}auth/social/google`, {
        token: res.tokenObj.id_token,
        type: dealer ? 2 : 1,
      }).then((res) => {
        if (res.data.result == 2) {
          toast.error("Account with this email already exists!");
        } else {
          /* Successful Login */
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.result));
          auth.activateToken(localStorage.setItem("token", res.data.token));
          auth.activateAuthentication(true);
          history.push("/user/my-inventory");
        }
      });
    }
  };

  return <GoogleLoginButton />;
};

export default Google;
