// const app = "http://192.168.10.115:3000/";
const app = "https://autoones.com/";
// const api = "http://192.168.10.115:5000/";
const api = "https://api.autoones.com/";
const phoneNumberMask = "+\\929999999999";

const beforeMaskedValueChange = (newState, oldState, userInput) => {
  var { value } = newState;
  var selection = newState.selection;
  var cursorPosition = selection ? selection.start : null;

  // keep minus if entered by user
  if (value.charAt(3) === "0") {
    value = value.slice(0, -1);
  }

  return {
    value,
    selection,
  };
};

export { app, api, phoneNumberMask, beforeMaskedValueChange };
