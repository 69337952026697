import { useRef } from "react";
import Axios from "axios";
import { Auth } from "../../../Context/Auth.context";
import { useHistory, Link } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";

const SignIn = ({ setState, hideAuth }) => {
  /* Reference to get values */
  const emailRef = useRef();
  const passRef = useRef();
  const rememberRef = useRef();

  const history = useHistory();
  const auth = Auth();

  /* Submit Form */
  const submit = async (e) => {
    e.preventDefault();

    var check = 0;
    /* Form Validators - Empty Check */
    emailRef.current.value.length === 0 && check++;
    passRef.current.value.length === 0 && check++;
    rememberRef.current.value.length === 0 && check++;

    if (check === 0) {
      document.getElementById("login-error-msg").innerHTML = "";

      var remember = rememberRef.current.checked ? 1 : 0;

      const formData = {
        email: emailRef.current.value,
        pass: passRef.current.value,
        remember: remember,
      };
      const result = await Axios.post(
        `${process.env.REACT_APP_API_URL}auth/login`,
        formData
      );
      if (result.data.error == 0) {
        /* Successful Login */
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.user));
        localStorage.setItem("fav", JSON.stringify(result.data.fav));
        auth.activateToken(localStorage.setItem("token", result.data.token));
        auth.activateAuthentication(true);
        hideAuth();
        history.push("/user/dashboard");
      } else if (result.data.error == 1)
        document.getElementById("login-error-msg").innerHTML =
          "No Account with this email";
      else if (result.data.error == 2)
        document.getElementById("login-error-msg").innerHTML =
          "Inccorrect Password";
      /* Error Conditions */
    } else
      document.getElementById("login-error-msg").innerHTML =
        "Email or Password are missing";
    /* Missing Inputs */
  };

  return (
    <form onSubmit={submit} className="py-4">
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Email or Username</Form.Label>
        <Form.Control
          ref={emailRef}
          type="text"
          className="form-control rounded-pill"
          placeholder="Email or Username"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          ref={passRef}
          type="password"
          className="form-control rounded-pill"
          placeholder="Password"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="remember">
        <Form.Check
          ref={rememberRef}
          type="checkbox"
          id="remember"
          label="Remember Me"
        />
      </Form.Group>
      <Row>
        <Col md={12}>
          <button className="btn-custom w-100 rounded-pill">Login</button>
        </Col>
      </Row>
      <div
        id="login-error-msg"
        className="mt-3 text-danger font-weight-bold"
        style={{ fontSize: "0.8rem" }}
      ></div>
    </form>
  );
};

export default SignIn;
