import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPlusCircle,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { faSearch, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import Sidebar from "./Sidebar/Sidebar";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Data } from "../../../Context/Data.context";
import "./styles.scss";

function MobileMenu() {
  const categories = Data().categories;
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [explore, setExplore] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div id="mobile-menu">
      <Container className="py-2">
        <div className="d-flex justify-content-between">
          <Link
            to="/"
            className={`${
              location.pathname == "/" ? `active` : ""
            } text-center`}
          >
            <p className="mb-0">
              <FontAwesomeIcon icon={faHome} />
            </p>
            <p className="mb-0">Home</p>
          </Link>
          <div
            onClick={() => setExplore(true)}
            className={`${
              location.pathname.includes("/search") ? `active` : ""
            } text-center`}
          >
            <p className="mb-0">
              <FontAwesomeIcon icon={faSearch} />
            </p>
            <p className="mb-0">Explore</p>
          </div>
          <div
            onClick={() => setOpen(true)}
            className={`${
              location.pathname == "/choose-category" ||
              location.pathname.includes("/add/")
                ? `active`
                : ""
            } text-center`}
          >
            <p className="mb-0">
              <FontAwesomeIcon icon={faPlusCircle} />
            </p>
            <p className="mb-0">Sell</p>
          </div>
          <Link
            to="/user/my-favorites"
            className={`${
              location.pathname == "/user/my-favorites" ? `active` : ""
            } text-center`}
          >
            <p className="mb-0">
              <FontAwesomeIcon icon={faHeart} />
            </p>
            <p className="mb-0">Saved</p>
          </Link>
          <a className="text-center">
            <Menu
              right
              id="bm-mobile-menu"
              onOpen={() => setMenuOpen(true)}
              onClose={() => setMenuOpen(false)}
              isOpen={menuOpen}
            >
              <Sidebar setState={setMenuOpen} />
            </Menu>
            <p className="mb-0">
              <FontAwesomeIcon icon={faBars} />
            </p>
            <p className="mb-0">Menu</p>
          </a>
        </div>
      </Container>

      <BottomSheet open={open} onDismiss={() => setOpen(false)}>
        <div className="py-3">
          <div className="container">
            <h5>Select Category For Ad Post</h5>
            <div className="row no-gutters">
              {categories &&
                categories.map((category, i) => (
                  <div key={i} onClick={() => setOpen(false)} className="col-4">
                    <Link
                      to={{
                        pathname: `/add/${category.slug}/`,
                        state: {
                          categoryId: category.id,
                          category: category.category,
                        },
                      }}
                    >
                      <div className="border border-danger rounded p-2 m-2">
                        <div className="d-flex justify-content-center">
                          <img
                            src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                            style={{
                              height: "40px",
                              width: "40px",
                            }}
                          />
                        </div>
                        <div className="text-dark text-center text-overflow">
                          {category.category}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </BottomSheet>

      <BottomSheet open={explore} onDismiss={() => setExplore(false)}>
        <div className="py-3">
          <div className="container">
            <h5>Select Category For Search</h5>
            <div className="row no-gutters">
              {categories &&
                categories.map((category, i) => (
                  <div
                    key={i}
                    onClick={() => setExplore(false)}
                    className="col-4"
                  >
                    <Link
                      to={{
                        pathname: `/search/${category.slug}/-/`,
                      }}
                    >
                      <div className="border border-danger rounded p-2 m-2">
                        <div className="d-flex justify-content-center">
                          <img
                            src={`${process.env.REACT_APP_AWS_URL}${category.logo}`}
                            style={{
                              height: "40px",
                              width: "40px",
                            }}
                          />
                        </div>
                        <div className="text-dark text-center text-overflow">
                          {category.category}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
}

export default MobileMenu;
