import { useState } from "react";
import { Link } from "react-router-dom";

import { FaRegEnvelope, FaArrowLeft } from "react-icons/fa";
import Email from "./Email/SignIn";
import Facebook from "./facebook";
import Google from "./google";

const SignIn = ({ setState, hideAuth }) => {
  const [display, setDisplay] = useState(false);

  return (
    <div id="sign-sidebar" className="d-flex">
      {display && (
        <div className="back" onClick={() => setDisplay(false)}>
          <FaArrowLeft /> Back
        </div>
      )}
      <div className="d-flex align-items-center w-100">
        <div className="px-5 w-100">
          <h2>Sign in</h2>
          {display ? (
            <Email hideAuth={hideAuth} />
          ) : (
            <>
              <p>
                New here?{" "}
                <a className="text-danger m-0" onClick={() => setState("signup")}>
                  Create an account
                </a>
              </p>
              <div className="social-btn">
                <Facebook />
                <Google />
                {/* <button className="btn py-3 mb-4 btn-outline-danger w-100 rounded-pill text-dark">
                  <FaPhoneAlt /> Continue With Mobile
                </button> */}
                <button onClick={() => setDisplay(true)} className="btn py-3 mb-4 btn-outline-danger w-100 rounded-pill text-dark">
                  <FaRegEnvelope /> Continue With Email
                </button>
                <p>
                  By continuing, you agree to our <Link to="/terms-conditions">Terms & Conditons</Link> and <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
              </div>
            </>
          )}
          <p>
            Forget Password?{" "}
            <Link to="/login?f=forget-password" className="text-danger m-0" onClick={hideAuth}>
              Reset Now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
