import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./index.css";
import "./CSS/App.css";
import "./CSS/nprogress.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "react-spring-bottom-sheet/dist/style.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-172627440-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById("root"));
