import React from "react";
import { Spinner } from "react-bootstrap";

const PageLoader = () => {
  return (
    <div className="my-5 py-5">
      <div className="d-flex justify-content-center">
        <Spinner animation="grow" variant="danger" />
      </div>
    </div>
  );
};

export default PageLoader;
