import { useState, useRef } from "react";
import Axios from "axios";
import { Auth } from "../../../Context/Auth.context";
import { useHistory, Link } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";

const SignUp = ({ setState, hideAuth }) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const usernameRef = useRef();
  const emailRef = useRef();
  const passRef = useRef();
  const confirmRef = useRef();
  const termsConditionsRef = useRef();
  const showRoomRef = useRef();
  const addressRef = useRef();

  /* States */
  const [showModal, setShowModal] = useState(false);
  const [dealer, setDealer] = useState(false);
  const verifyDiv = useRef();

  const submit = async (e) => {
    e.preventDefault();

    var check = 0;
    /* Form Validators - Empty Check */
    firstNameRef.current.value.length === 0 && check++;
    lastNameRef.current.value.length === 0 && check++;
    usernameRef.current.value.length === 0 && check++;
    emailRef.current.value.length === 0 && check++;
    passRef.current.value.length === 0 && check++;
    if (dealer) {
      showRoomRef.current.value.length === 0 && check++;
      addressRef.current.value.length === 0 && check++;
    }
    /* Form Validators - Custom Check */
    (await verifyUsername(usernameRef.current.value)) && check++;
    (await verifyEmail(emailRef.current.value)) && check++;
    (await matchPass(passRef.current.value, confirmRef.current.value)) && check++;

    if (check === 0) {
      /* Check if Terms & Conditions are checked or not */
      if (termsConditionsRef.current.checked) {
        const formData = {
          name: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
          username: usernameRef.current.value,
          email: emailRef.current.value,
          pass: passRef.current.value,
          type: dealer ? 2 : 1,
        };
        if (dealer) {
          formData["showroom"] = showRoomRef.current.value;
          formData["address"] = addressRef.current.value;
        }
        const result = await Axios.post(`${process.env.REACT_APP_API_URL}auth/register/`, formData);

        /* Verify User Account */
        if (result.data.id) {
          setShowModal(true);
          verifyDiv.current.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }

        document.getElementById("termsconditions-div").style.color = "#333";
      } else {
        document.getElementById("termsconditions-div").style.color = "red";
      }
    }
  };

  return (
    <div>
      <form onSubmit={submit} className="py-4">
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="fname">
              <Form.Label>First Name</Form.Label>
              <Form.Control ref={firstNameRef} type="text" className="form-control rounded-pill" placeholder="First Name" />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="lname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control ref={lastNameRef} type="text" className="form-control rounded-pill" placeholder="Last Name" />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>
                Username
                <span id="username-error" className="text-danger" style={{ fontSize: "0.7rem" }}></span>
                <span id="username-success" className="text-success" style={{ fontSize: "0.7rem" }}></span>
              </Form.Label>
              <Form.Control ref={usernameRef} onChange={(e) => verifyUsername(e.target.value)} type="text" className="form-control rounded-pill" placeholder="Username" />
            </Form.Group>
          </Col>
          {dealer && (
            <>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="fname">
                  <Form.Label>Showroom Name</Form.Label>
                  <Form.Control ref={showRoomRef} type="text" className="form-control rounded-pill" placeholder="Showroom name" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="lname">
                  <Form.Label>Address</Form.Label>
                  <Form.Control ref={addressRef} type="text" className="form-control rounded-pill" placeholder="Address" />
                </Form.Group>
              </Col>
            </>
          )}
          <Col md={12}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>
                Email
                <span id="email-error" className="text-danger" style={{ fontSize: "0.7rem" }}></span>
                <span id="email-success" className="text-success" style={{ fontSize: "0.7rem" }}></span>
              </Form.Label>
              <Form.Control ref={emailRef} onChange={(e) => verifyEmail(e.target.value)} type="text" className="form-control rounded-pill" placeholder="Email" />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control ref={passRef} type="password" className="form-control rounded-pill" placeholder="Password" />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="confirm">
              <Form.Label>
                Confirm Password
                <span id="confirm-error" className="text-danger" style={{ fontSize: "0.7rem" }}></span>
              </Form.Label>
              <Form.Control ref={confirmRef} onChange={(e) => matchPass(e.target.value, passRef.current.value)} type="password" className="form-control rounded-pill" placeholder="Confirm Password" />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Form.Group id="termsconditions-div" className="mb-3 d-flex flex-row" controlId="termsconditions">
                  <Form.Check ref={termsConditionsRef} type="checkbox" label="I accept the" />
                  <Link className="ml-1 font-weight-bold" style={{ color: "#AA2625" }} to="/terms-conditions">
                    Terms and Conditions
                  </Link>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="mb-3 mb-md-0 d-flex justify-content-md-end">
                  <span
                    className="font-weight-bold"
                    onClick={() => setDealer(!dealer)}
                    style={{
                      color: "#AA2625",
                      fontSize: "0.9rem",
                      cursor: "pointer",
                    }}
                  >
                    Login as
                    {dealer ? " Indiviual" : " Dealer"}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <span id="error-msgs" className="text-danger" style={{ fontSize: "0.8rem" }}></span>
            <Row>
              <Col sm={12}>
                <button type="submit" className="btn-custom m-1 w-100 rounded-pill">
                  Register Now
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
      <div id="verify" className="mb-4" ref={verifyDiv}>
        {showModal && (
          <div className="mt-3 container">
            <h5 className="font-weight-bold">Verify Your Account</h5>
            <hr style={{ borderWidth: "2px" }} />
            <p className="mb-0">An Email with the verification link has been sent to your email. Please! Verify your account to procced.</p>
            <div className="text-muted font-weight-bold" style={{ fontSize: "0.8rem" }}>
              Check your spam folder, in case you don't see the email in the inbox
            </div>

            <button className="btn-custom mt-2">Resend Email</button>
          </div>
        )}
      </div>
    </div>
  );
};

/* Function to verify username */
const verifyUsername = async (username) => {
  var usernameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
  if (usernameRegex.test(username)) {
    document.getElementById("username-error").innerHTML = "";

    /* Verify, if the username is duplicate or not */
    const result = await duplicate("username", username);
    if (result.data.data) {
      document.getElementById("username-success").innerHTML = "";
      document.getElementById("username-error").innerHTML = " Username Unavailable";
      return true;
    } else {
      document.getElementById("username-error").innerHTML = "";
      document.getElementById("username-success").innerHTML = " Username Available";
      return false;
    }
  } else {
    document.getElementById("username-error").innerHTML = " Invalid Username Format";
    document.getElementById("username-success").innerHTML = "";
    return true;
  }
};

/* Function to verify email */
const verifyEmail = async (email) => {
  var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRegex.test(email)) {
    document.getElementById("email-error").innerHTML = "";

    /* Verify, if the email is duplicate or not */
    const result = await duplicate("email", email);
    if (result.data.data) {
      document.getElementById("email-error").innerHTML = " Email Unavailable";
      document.getElementById("email-success").innerHTML = "";
      return true;
    } else {
      document.getElementById("email-error").innerHTML = "";
      document.getElementById("email-success").innerHTML = " Email Available";
      return false;
    }
  } else {
    document.getElementById("email-error").innerHTML = " Invalid Email Format";
    document.getElementById("email-success").innerHTML = "";
    return true;
  }
};

/* Function to match password */
const matchPass = (confirm, password) => {
  if (confirm.length != 0 || password.length != 0) {
    if (password === confirm) {
      document.getElementById("confirm-error").innerHTML = "";
      return false;
    } else {
      document.getElementById("confirm-error").innerHTML = " Password doesn't match";
      return true;
    }
  } else {
    return true;
  }
};

/* Duplicate Checker Function */
const duplicate = async (cell, value) => {
  const result = await Axios.post(`${process.env.REACT_APP_API_URL}auth/duplicate`, { cell: cell, value: value });
  return result;
};

export default SignUp;
